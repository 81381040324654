import s from './edit-teacher-profile-modal.module.scss'
import { Modal } from 'shared/modal/modal';
import { useCallback, useState } from 'react';
import { useUpdateProfile } from 'hooks/useUpdateProfile';
import { useDropzone } from 'react-dropzone';
import { useProfile } from 'hooks/useProfile';
import { profile as apiProfile } from 'api/api'
import { IconCheck, IconEdit, IconPlus, IconTrash, IconVideo, IconX } from '@tabler/icons-react';
import { getNoun } from 'helpers/getNoun';
import { Button } from 'shared/button/button';
import { toast } from 'react-toastify';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useAllInstruments } from 'hooks/useAllInstruments';
import Select, { components } from 'react-select';
import { VideoViewer } from '../../../shared/video-viewer/video-viewer';
import { useAnalytics } from '../../../../hooks/useAnalytics';
import { getFullName } from '../../../../helpers/getFullname';

const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
				<Button label={<IconPlus size={16}/>} size="small" className={s.plusBtn}/>
      </components.DropdownIndicator>
    )
  );
};

const MultiValueRemove = props => {
  return (
    components.MultiValueRemove && (
      <components.MultiValueRemove {...props}>
				<IconX size={16}/> 
      </components.MultiValueRemove>
    )
  );
};

export const EditTeacherProfileModal = ({isOpen, setOpen}) => {
	const [isOpenVideo, setIsOpenVideo] = useState(false)

	const {data: profile} = useProfile()
	const {data: analytics} = useAnalytics()
	const {data: instruments} = useAllInstruments()
	const {mutate: updateProfile} = useUpdateProfile()

	const methods = useForm({
		defaultValues: {
			instruments: profile?.instruments?.map(el => ({value: el.id, label: el.name}))
		}})
	const {control} = methods

	const [description, setDescription] = useState(profile?.teacher_bio)

	const [myFiles, setMyFiles] = useState([])
	const [editableDesc, setEditableDesc] = useState(false)
	const [editableInstruments, setEditableInstruments] = useState(false)

  const onDrop = useCallback(acceptedFiles => {
    setMyFiles([...myFiles, ...acceptedFiles])
  }, [myFiles])


	const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
		maxFiles: 1,
		accept: {
			'video/mp4': ['.mp4'],
			'video/avi': ['.avi'],
			'video/webm': ['.webm'],
			'video/ogg': ['.ogg'],
		},
		onDrop,
	});

  const files = myFiles.map(file => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
    </li>
  ));

  const removeFiles = () => {
    setMyFiles([])
  }

  return (
		<>
			<VideoViewer isOpen={isOpenVideo} setOpen={setIsOpenVideo} url={profile?.video_presentation}/>	
			<Modal {...{isOpen, setOpen}} name={'edit-teacher-profile-modal'}>
				<div className={s.card}>
					<div className={s.baseInfo}>
						<div className={s.avatar}>
								<img
									src={
										profile?.avatar
											? process.env.REACT_APP_STORAGE_URL + profile?.avatar.image
											: process.env.REACT_APP_STORAGE_URL + "images/default_avatar.png"
									}
									alt=""
								/>
								<button className={s.edit} onClick={() => setOpen('update-avatar-modal')}>
									<IconEdit size={24} color='#fff'/>
								</button>
							</div>
						<div className={s.baseInfoContent}>
							<h5 className={s.name}>
								{getFullName(profile)}
							</h5>
							<div className={s.videoBtns}>
								{profile?.video_presentation && <button className={s.videoBtn} onClick={() => setIsOpenVideo(true)}><IconVideo size={18}/> Посмотреть видеообращение</button>}
								<button className={s.editVideoBtn} {...getRootProps()}>
									<input {...getInputProps()} />
									{!profile?.video_presentation ? "Добавить видеообращение" : <IconEdit size={18}/> }
								</button>
							</div>
							<div className={s.infos}>
								<p>🌟 {profile.rating}</p>
								<p>📖 {analytics?.appointments_count}{getNoun(analytics?.appointments_count, " занятие", " занятия", " занятий")}</p>
								<p>
									💬 {profile.reviews?.length}{" "}
									{getNoun(profile.reviews?.length, "отзыв", "отзыва", "отзывов")}
								</p>
							</div>
						</div>
					</div>
					{files.length >= 1 && 
						<div className={s.fileWrapper}>
							<p>{files[0].key}</p>
							<div className={s.btns}>
								<button className={s.removeBtn} onClick={removeFiles}><IconTrash/></button>
								<Button label={'Загрузить'} className={s.saveBtn} size="small" onClick={() => {
									apiProfile.updateVideoPresentation(acceptedFiles[0]).then(() => {
										toast.success('Видео загружено')
										removeFiles()
									})
								}}/>
							</div>
						</div>}
					<div className={s.moreInfo}>
						<FormProvider {...methods}>
							<div className={s.instruments}>
							{!editableInstruments ?
								<div className={s.instrumentsLabel}>
									{profile?.instruments?.map((i) => (
										<div key={'instrument-key-' + i?.name} className={s.instrumentTag}>{i?.name}</div>
									))}
									<button className={s.editBtn} onClick={() => setEditableInstruments(true)}><IconEdit/></button>
								</div> :
								<Controller
								name="instruments"
								control={control}
								render={({ field }) => (
									<div className={s.instrumentsEdit}>
										<Select
											{...field} 
											options={instruments?.map(i => ({label: i.name, options: i?.instruments?.map(ingroup => ({value: ingroup.id, label: ingroup.name}))}))} 
											isMulti 
											isSearchable={false}
											backspaceRemovesValue
											placeholder="Выберите инструменты"
											className={s.select}
											onChange={(value) => {
												field.onChange(value);
											}}
											styles={{
												control: (baseStyles, state) => ({
													...baseStyles,
													outline: 'none',
													boxShadow: 'none',
													padding: 0,
													border: 'none',
													borderRadius: '.75rem',
													minHeight: 0
												}),
												valueContainer: (baseStyles) => ({
													...baseStyles,
													padding: 0,
												}),
												multiValue: (baseStyles) => ({
													...baseStyles,
													backgroundColor: '#f1f1f1',
													borderRadius: '.5rem',
													color: '#000',
													padding: '.5rem .35rem',
													"&:hover": {
														background: '#dcdcdc'
													}
												}),
												multiValueRemove: (baseStyles) => ({
													...baseStyles,
													cursor: 'pointer',
													"&:hover": {
														background: 'none'
													}
												}),
												menu: (baseStyles) => ({
													...baseStyles,
													borderRadius: 10,
													padding: 8,
													boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px'
												}),
												option: (baseStyles, {isSelected}) => ({
													...baseStyles,
													padding: '0.5rem 1rem',
													borderRadius: 4,
													color: '#4F5157',
													fontSize: '1rem',
													lineHeight: '1.5rem',
													cursor: 'pointer',
													background: isSelected ? '#f1f1f1' : 'none', 
													"&:hover": {
														background: '#f1f1f1'
													}
												}),
												multiValueLabel: (baseStyles) => ({
													...baseStyles,
													color: '#000',
													fontSize: '.875rem',
													fontWeight: '500',
													lineHeight: '100%',
												}),
											}}
											isClearable={false}
											components={{DropdownIndicator, MultiValueRemove, IndicatorSeparator: ''}}/>
											<div className={s.btns}>
												<Button label={'Отмена'} size={'small'} theme='secondary' onClick={() => {
													setEditableInstruments(false)
												}}/>
												<Button label={'Сохранить'} disabled={!field.value.length} size={'small'} onClick={() => {
													updateProfile({instruments: field.value?.map((instrument) => instrument.value)})
													setEditableInstruments(false)
												}}/>
											</div>
									</div>
									)}
								/>}
							</div>
							<div className={s.desc}>
								<span className={s.descLabel}>О себе {editableDesc || <button className={s.editBtn} onClick={() => setEditableDesc(true)}><IconEdit/></button>}</span>
								{editableDesc ? 
								<>
									<textarea className={s.textarea}
									placeholder="Текст..."
									value={description}
									onChange={(e) => setDescription(e?.currentTarget?.value)}/>
									<div className={s.btns}>
										<Button label={'Отмена'} size={'small'} theme='secondary' onClick={() => {
											setEditableDesc(false)
											setDescription(profile?.teacher_bio)
										}}/>
										<Button label={'Сохранить'} size={'small'} onClick={() => {
											updateProfile({teacher_bio: description})
											setEditableDesc(false)
										}}/>
									</div>
								</>
								: <span className={s.descContent}>{profile?.teacher_bio}</span>}
							</div>
							
							<div className={s.education}>
								<span className={s.educationLabel}>Образование и опыт</span>
								{profile?.teacher_education?.map((e) => (
									<span className={s.educationContent} key={'education-key-' + e}>
										<IconCheck className={s.icon} />
										{e}
									</span>
								))}
							</div>
						</FormProvider>
					</div>
				</div>
			</Modal>
		</>
  );
};