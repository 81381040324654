import { useMutation, useQueryClient } from "@tanstack/react-query"
import { students } from "../api/api"

export const useUpdateStudent = (onSuccess = () => {}) => {
	const queryClient = useQueryClient()

	const mutation = useMutation({
		mutationFn: students.updateStudent,
    onSuccess: (data) => {
      queryClient.invalidateQueries(['student', data.email])
			onSuccess()
    },
  })

	return mutation
}