import React from 'react'
import s from './footer.module.scss'
import { NavLink } from 'react-router-dom';

export const Footer = () => {
	return (
		<footer className={s.wrapper}>
			<div className="container">
				<div className={s.flex}>
					<NavLink to={'/'} className={s.logo}>
						<img src={process.env.REACT_APP_STORAGE_URL + "images/logo.svg"} alt="Созвучно - сервис по поиску преподавателей музыки"/>
					</NavLink>
					<ul>
						<li>
							<a href="mailto:contact@sozvuchno.ru">Поддержка</a>
						</li>
						<li>
							<NavLink to={'/about'}>Правила использования</NavLink>
						</li>
						<li>
							<NavLink to={'/policy'}>Политика конфиденциальности</NavLink>
						</li>
					</ul>
				</div>
			</div>
		</footer>
	)
}
