import classNames from 'classnames'
import s from './ToggleSimple.module.scss'

export const ToggleSimple = ({ value, onChange, label, className }) => {
  return (
		<div className={classNames(s.toggleFlex, className)}>
      {label && <p className={s.toggleLabel}>{label}</p> }
			<div className={`${s.toggleWrapper} ${value ? s.active : ''}`} onClick={() => onChange(p => !p)}>
				<span onClick={() => onChange(p => !p)}></span>
			</div>
		</div>
  );
};