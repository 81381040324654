import { useQuery } from "@tanstack/react-query"
import { appointment } from "../api/api"

export const useAppointmentRecord = (id) => {
	return useQuery({
		queryKey: ['appointment-record'],
		queryFn: () => appointment.getRecord(id),
		enabled: !!id,
		select: (data) => data.data,
		retry: 3
	})
}