import React, { useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { Input } from 'shared/input/input'
import { Button } from 'shared/button/button';
import s from './reset-password.module.scss'
import { FormProvider, useForm } from 'react-hook-form';
import { auth } from 'api/api';

export const ResetPassword = () => {
	const methods = useForm({mode: 'onTouched'})
	const navigate = useNavigate()
	const { token,email } = useParams()

  const handleSubmit = methods.handleSubmit(data => {
		const payload = {
			token,
			email,
			password: data.password,
			password_confirmation: data.password_confirmation
	}
	
		auth.resetPassword(payload).then((res) => {
			if(res.status == 200){
				navigate('/login')
			}
		})
  })

	return (
		<div className={s.wrapper}>
			<div className={s.card}>
				<NavLink to={'/'} className={s.logo}>
					<img src={process.env.REACT_APP_STORAGE_URL + "images/logo.svg"} alt="Созвучно - сервис по поиску преподавателей музыки"/>
				</NavLink>
				<h2 className={s.title}>
					Восстановление пароля
				</h2>
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit} className={s.form}>
						<Input 
							type="password" 
							placeholder='Новый пароль' name="password" 
							className={s.input}
							validation={{
								required: {
									value: true,
									message: 'Поле незаполнено',
								},
								minLength: {
									value: 8,
									message: 'Минимум 8 символов',
								},
								maxLength: {
									value: 50,
									message: 'Максимум 50 символов',
								},
							}}/>
						<Input 
							type="password" 
							placeholder='Повторите пароль' name="password_confirmation" 
							className={s.input}
							validation={{
								required: {
									value: true,
									message: 'Поле незаполнено',
								},
								minLength: {
									value: 8,
									message: 'Минимум 8 символов',
								},
								maxLength: {
									value: 50,
									message: 'Максимум 50 символов',
								},
								validate: value => value === methods.watch("password") || "Пароли не совпадают"
							}}/>
						<Button label='Восстановить' className={s.button}/>
					</form>
				</FormProvider>
			</div>
		</div>
	)
}
