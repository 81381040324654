import React from 'react'
import s from './promo.module.scss'
import { Button } from '../button/button';
import classNames from 'classnames';

export const Promo = ({className}) => {
	return (
		<div className={classNames(className, s.card)}>
			<div className={s.title}>Промокод для друзей</div>
			<div className={s.body}>
				<span className={s.colored}>10%</span> скидка на оплату первого занятия <br />
				для твоего друга по промокоду: <span className={s.colored}>ДругСозвучно</span>
			</div>
			<Button label={'Поделиться'} className={s.btn} />
			<img src={process.env.REACT_APP_STORAGE_URL + "images/gift-dynamic-color.png"} alt="" className={s.img}/>
		</div>
	)
}
