import React from 'react'
import s from './dashboard-bottombar.module.scss'
import { NavLink, useNavigate } from 'react-router-dom'
import { IconHistory, IconHome, IconMessage, IconMessages, IconUser } from '@tabler/icons-react'
import { useProfile } from 'hooks/useProfile';
import classNames from 'classnames';
import { useWebSocketContext } from '../../../context/WebSocketProvider';

export const DashboardBottombar = () => {
	const {data: profile} = useProfile()
	const {chats} = useWebSocketContext()

	if(/\/appointment\//.test(window.location.href)){
		return null
	}

	return (
		<div className={s.wrapper}>
			{{'student':
			<ul className={s.menuList}>
				<li className={s.menuItem}>
					<NavLink to={'/'} className={({ isActive }) =>  isActive ? s.active : ""}><IconHome/> <span>Главная</span></NavLink>
				</li>
				<li className={classNames(s.menuItem, s.messenger, chats?.findIndex(_ => _?.last_message?.sender_id !== profile.id && _?.last_message?.is_read === false) !== -1 ? s.unread : '')}>
					<NavLink to={'/messenger'} className={({ isActive }) =>  isActive ? s.active : ""}><IconMessage/> <span>Сообщения</span></NavLink>
				</li>
				<li className={s.menuItem}>
					<NavLink to={'/history'} className={({ isActive }) =>  isActive ? s.active : ""}><IconHistory/><span> История</span></NavLink>
				</li>
				<li className={s.menuItem}>
					<NavLink to={'/settings'} className={({ isActive }) =>  isActive ? s.active : ""}><IconUser/><span> Профиль</span></NavLink>
				</li>
			</ul>,
			'teacher':
			<ul className={s.menuList}>
				<li className={s.menuItem}>
					<NavLink to={'/'} className={({ isActive }) =>  isActive ? s.active : ""}><IconHome/> <span>Главная</span></NavLink>
				</li>
				<li className={classNames(s.menuItem, s.messenger, chats?.findIndex(_ => _?.last_message?.sender_id !== profile.id && _?.last_message?.is_read === false) !== -1 ? s.unread : '')}>
					<NavLink to={'/messenger'} className={({ isActive }) =>  isActive ? s.active : ""}><IconMessages/> <span>Сообщения</span></NavLink>
				</li>
				<li className={s.menuItem}>
					<NavLink to={'/history'} className={({ isActive }) =>  isActive ? s.active : ""}><IconHistory/> <span>История</span></NavLink>
				</li>
				<li className={s.menuItem}>
					<NavLink to={'/settings'} className={({ isActive }) =>  isActive ? s.active : ""}><IconUser/><span> Профиль</span></NavLink>
				</li>
			</ul>}[profile?.role]}
		</div>
	)
}
