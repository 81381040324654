import React from 'react'
import s from './dashboard-header.module.scss'
import { IconMenu2 } from '@tabler/icons-react'
import { NavLink } from 'react-router-dom'

export const DashboardHeader = ({set_showSidebar, profile, isMobile}) => {

	return (
		<header className={s.wrapper}>
			{isMobile ?
			<div className={s.logo}>
				<img src={process.env.REACT_APP_STORAGE_URL + "images/logo.svg"} alt="Созвучно - сервис по поиску преподавателей музыки" />
			</div>
			: 
			<button className={s.burgerBtn} onClick={() => set_showSidebar(prev => !prev)}>
				<IconMenu2/>
			</button>}
			<NavLink to={'/settings'} className={s.avatar}>
				<img src={profile.avatar ? process.env.REACT_APP_STORAGE_URL + profile.avatar.image : process.env.REACT_APP_STORAGE_URL + 'images/default_avatar.png'} alt="" />
			</NavLink>
		</header>
	)
}
