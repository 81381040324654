import React from 'react'
import s from './header.module.scss'
import { NavLink } from 'react-router-dom';

export const Header = () => {

	return (
		<>
			<header className={s.wrapper}>
				<div className='container'>
					<div className={s.flex}>
						<NavLink to={'/'} className={s.logo}>
							<img src={process.env.REACT_APP_STORAGE_URL + "images/logo.svg"} alt="Созвучно - сервис по поиску преподавателей музыки"/>
						</NavLink>
						
						<NavLink to={'/sign-up'} className={s.btn}>Начать заниматься</NavLink>
					</div>
				</div>
			</header>
			<div className={s.space}></div>
		</>
	)
}
