import React, { useCallback, useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize';
import { Button } from '../../shared/button/button';
import { IconFile, IconPaperclip, IconTrash } from '@tabler/icons-react';
import { useDropzone } from 'react-dropzone';
import { useUpdateHomework } from '../../../hooks/useUpdateHomework';
import s from './edit-note-card.module.scss'
import classNames from 'classnames';

export const EditNote = ({ homework, onSuccess, appointmentId }) => {
  const [text, setText] = useState(homework?.task);
  const [myFiles, setMyFiles] = useState(homework?.attachments);
	const [isLoading, setIsLoading] = useState(false)

  const { mutate: editNote } = useUpdateHomework(appointmentId, () => {
		setIsLoading(false)
		onSuccess()
	});

  const onDrop = useCallback(
    (acceptedFiles) => {
      setMyFiles([...myFiles, ...acceptedFiles]);
    },
    [myFiles]
  );

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 5,
    onDrop,
  });

  const removeFiles = (name) => {
    setMyFiles((prev) => prev.filter((el) => el.name !== name));
  };

  const onClick = () => {
		setIsLoading(true)
    editNote({
      appointmentId,
      homeworkId: homework.id,
      data: {
        task: text,
      },
      attachments: acceptedFiles,
      filesId: myFiles?.map((el) => el.id),
    });
  };
  const onCancel = () => {
    onSuccess();
  };

  return (
    <div className={classNames(s.addNoteCard, isLoading ? s.isLoading : '')}>
      <TextareaAutosize
        onChange={(v) => setText(v.target.value)}
        value={text}
        maxRows={5}
        placeholder="Начните печатать здесь...."
      ></TextareaAutosize>
			{myFiles.length >= 1 &&
        myFiles.map((el) => (
          <div className={s.fileWrapper}>
            <p>
              <div className={s.icon}>
                <IconFile size={18} />
              </div>
              {el.name} - {el.size}
            </p>
            <button
              className={s.removeBtn}
              onClick={() => removeFiles(el.name)}
            >
              <IconTrash size={18} color={"#F12828"} />
            </button>
          </div>
        ))}
      <div className={s.jcsb}>
        <Button
          label="Отменить"
          size="small"
          theme={"secondary"}
          onClick={onCancel}
        />
				<div className={s.btns}>
					<input {...getInputProps()} />
					<Button label={<IconPaperclip size={16}/>} {...getRootProps()} size="small" theme='secondary' className={s.attachmentButton}/>
        	<Button label="Сохранить" size="small" onClick={onClick} />
				</div>
      </div>
    </div>
  );
};