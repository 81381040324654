import React from "react";
import "@livekit/components-styles";
import {
  ParticipantTile,
  RoomAudioRenderer,
  useTracks,
} from "@livekit/components-react";
import { Track } from "livekit-client";
import s from "./video-conf.module.scss";
import { ControlBar } from "./control-bar";
import { Layout } from "./layout";
import { Button } from "../button/button";
import classNames from "classnames";

function MyVideoConference() {
  const tracks = useTracks([
    { source: Track.Source.Camera, withPlaceholder: false },
  ]);

  return (
    <Layout tracks={tracks}>
      <ParticipantTile />
    </Layout>
  );
}

export const VideoConf = ({ isConf, setIsConf, downloadDisabled, appointmentRecord, shouldRequest, disabled, confIsEnd, onDownload }) => {
  return (
    <>
      {isConf ? (
        <div className={s.wrapper}>
          <MyVideoConference />
          <RoomAudioRenderer />
          <ControlBar />
        </div>
      ) : (
        <>
          <div className={classNames(s.emptyText, confIsEnd && appointmentRecord ? s.withVideo : '')}>
						{confIsEnd ? <>
							{appointmentRecord ? <video 
								className={s.video} 
								controls={true}>
								<source src={appointmentRecord.url} type="video/mp4" />
							</video> : 'Запись урока еще не загрузилась'}
						</> : 
            	shouldRequest
              ? "Вы уже можете подключиться к конференции"
              : "Конференция пока что не началась"
						}
          </div>
          <div className={s.buttonWrapper}>
           {confIsEnd ?
							null
					  : <Button
              label="Подключиться"
              disabled={disabled}
              size="small"
              onClick={() => setIsConf(true)}
            />}
          </div>
        </>
      )}
    </>
  );
};
