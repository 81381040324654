import React, { useContext } from 'react'
import s from './settings.module.scss'
import { IconEdit } from '@tabler/icons-react'
import { Input } from 'shared/input/input'
import { Button } from 'shared/button/button'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { CalendarInput } from 'shared/calendar-input/calendar-input'
import { Promo } from 'shared/promo/promo'
import { ModalsContext } from '../../../components/layouts/dashboard-layout/dashboard'
import { useProfile } from 'hooks/useProfile'
import { useUpdateProfile } from 'hooks/useUpdateProfile'
import { Notifications } from 'shared/notification-settings/notification';
import { useMedia } from 'use-media'
import { auth } from '../../../api/api';
import { SettingsForm } from '../../../components/shared/settings-form/settings-form';
import { UpdatePassword } from '../../../components/shared/update-password/update-password'

export const Settings = () => {
	const {data: profile} = useProfile()

	const methods = useForm({
		defaultValues: {...profile, fname: profile.fname || '', lname: profile.lname || ''},
		mode: "onChange"
	})

	const {mutate: updateProfile} = useUpdateProfile()

	const {control, formState} = methods
	const {setModal} = useContext(ModalsContext)

	const isMobile = useMedia({maxWidth: '768px'})

  const handleSubmit = methods.handleSubmit(data => {
		const d = data.bday ? new Date(data.bday) : null	
		const day = d ? +d.getDate() : null			
		const selectedMonth = d ? +d.getMonth() + 1 : null
		const selectedYear = d ? +d.getFullYear() : null

		const payload = {
			email: data?.email,
			name: data?.name,
			fname: data?.fname ? data?.fname : null,
			lname: data?.lname ? data?.lname : null,
			bday: data.bday ? `${(day + '').length === 2 ? day : '0' + day}.${(selectedMonth + '').length === 2 ? selectedMonth : '0' + selectedMonth }.${selectedYear}` : null,
		}
		
		updateProfile(payload)
  })

	const newValues = () => {
		const name = methods.watch('name').trim();
		const fname = methods.watch('fname').trim();
		const lname = methods.watch('lname').trim();
		const email = methods.watch('email').trim();
		const bday = methods.watch('bday')
		return name !== profile.name || fname !== profile.fname || lname !== profile.lname || email !== profile.email || bday !== profile.bday
	}

	return (
		<div className={s.wrapper}>
			<div className={s.title}>Настройки</div>
			<div className={s.flex}>
				<div className={s.card}>
					<div className={s.profile}>
						<div className={s.avatar}>
							<button className={s.edit} onClick={() => setModal('update-avatar-modal')}>
								<IconEdit size={24} color='#fff'/>
							</button>
							<img src={profile.avatar ? process.env.REACT_APP_STORAGE_URL + profile.avatar.image : process.env.REACT_APP_STORAGE_URL + 'images/default_avatar.png'} alt="" />
						</div>
						<div className={s.profileContent}>
							<div className={s.name}>{profile?.name}</div>
							<div className={s.role}>{profile?.email}</div>
						</div>
					</div>
					<SettingsForm/>
					<UpdatePassword/>
				</div>
				<div className={s.col}>
					<Promo/>
					<Notifications/>
					<div className={s.card}>
						<button className={s.logout} onClick={() => auth.logout()}>Выйти</button>
					</div>
				</div>
			</div>
		</div>
	)
}