import React from 'react'
import { PaymentInputsContainer } from 'react-payment-inputs'
import images from 'react-payment-inputs/images';
import s from './payment-input.module.scss'

export const PaymentInput = () => {
	return (
		<PaymentInputsContainer>
			{({ meta, getCardNumberProps, getExpiryDateProps, getCardImageProps }) => (
				<>
					<div className={s.wrapper}>
						<svg {...getCardImageProps({ images })} />
						<input {...getCardNumberProps()} />
						<input {...getExpiryDateProps()} />
					</div>
					<div className={s.error}>
						{meta.isTouched && meta.error && <span>Error: {meta.error}</span>}
					</div>
				</>
			)}
		</PaymentInputsContainer>
	)
}
