import React, { useState } from 'react'
import s from './voice-recorder.module.scss'
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { AudioPlayer } from '../audio-player/audio-player';


export const VoiceRecorder = ({onRecordingComplete, blob}) => {
	const [isRecorded, setIsRecorded] = useState(false)
	
	const recorderControls = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true,
    },
    (err) => console.table(err)
  );

  return (
    <>
			{blob ? <AudioPlayer file={blob} remove={() => {
				onRecordingComplete(null)
			}}/> :
			<AudioRecorder
				onRecordingComplete={(f) => {
					if(isRecorded){
						onRecordingComplete(null)
						setIsRecorded(false)
					}else{
						onRecordingComplete(f)
						setIsRecorded(true)
					}
				}}
				recorderControls={recorderControls}
				showVisualizer={true}
			/> }
    </>
  );
}
