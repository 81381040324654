import React, { useState } from 'react'
import s from './teacher.module.scss'
import { ChooseDate } from '../../../components/profile/choose-date/choose-date'
import { useNavigate, useParams } from 'react-router-dom'
import { classes } from 'api/api'
import { getNoun } from 'helpers/getNoun'
import { useTeacher } from 'hooks/useTeacher'
import { Loader } from 'shared/loader/loader';
import { useOutsidePayments } from '../../../hooks/useOutsidePayments';

export const Teacher = () => {
	const [selectedDate, set_selectedDate] = useState(null)
	const [selectedTime, set_selectedTime] = useState(null)
	const [selectedInstrument, set_selectedInstrument] = useState(null)

	const { id } = useParams()
	const navigate = useNavigate()
	const {data: teacher, isFetched} = useTeacher(id)
	const {data: isOutsidePayments} = useOutsidePayments(id)

	const createClass = () => {
		classes.createClass(teacher?.id, selectedTime.length === 5 ? selectedTime : '0' + selectedTime, selectedDate, selectedInstrument)
		.then(res => {
			if(isOutsidePayments){
				navigate('/post-payment/' + res?.data?.id)
			}else{
				if(res?.status == 201){
					classes.payForAppointment(res?.data?.id).then(payment => {
						window.open(payment.data, "_self")
					})
				}
			}
		})
	}

	return (
		<div className={s.wrapper}>
			{isFetched ?  
			<>
				<div className={s.title}>Забронировать занятие</div>
				<div className={s.flex}>
					<div className={s.card}>
						<div className={s.baseInfo}>
							<div className={s.avatar}>
								<img src={teacher?.avatar ? process.env.REACT_APP_STORAGE_URL + teacher?.avatar.image : process.env.REACT_APP_STORAGE_URL + 'images/default_avatar.png'} alt="" />
							</div>
							<div>
								<h5 className={s.name}>
									{teacher?.name}
								</h5>
								<div className={s.infos}>
								<p>🌟 {teacher?.rating}</p>
								<p>📖 {teacher?.appointments_count}{getNoun(teacher?.appointments_count, " занятие", " занятия", " занятий")}</p>
								<p>💬 {teacher?.reviews?.length} {getNoun(teacher?.reviews?.length, 'отзыв', 'отзыва', 'отзывов')}</p>
								</div>
							</div>
						</div>
						<ChooseDate {...{teacher, isOutsidePayments, selectedDate, createClass, set_selectedDate, selectedTime, set_selectedTime, selectedInstrument, set_selectedInstrument}} />
					</div>
				</div>
			</> : <Loader/>}
		</div>
	)
}