import s from './chat.module.scss'
import classNames from 'classnames'
import InfiniteScroll from 'react-infinite-scroll-component';
import { Message } from './message';
import { Loader } from 'shared/loader/loader';
import { SendBlock } from './sendBlock';
import { IconChevronLeft } from '@tabler/icons-react';

export const Messenger = ({isMobile, setSelectedChat, emojiShow, setEmojiShow, chat, isEnd, onRead, handleEnter, setText, fetchMoreData, text, profile, messageHistory, file, setFile}) => {
	const months = [
    'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
    'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
  ]

	return (
		<div className={s.messengerWrapper}>
			{chat ? 
			<>
				<div className={s.messengerHeader}>
					{isMobile && <button onClick={() => setSelectedChat(null)} className={s.backBtn}><IconChevronLeft/></button>}
					<div className={s.avatar}>
						<img src={chat?.participants[0]?.role === 'service' ? '/logo192.png' :
							(chat?.participants[0]?.avatar ? process.env.REACT_APP_STORAGE_URL + chat?.participants[0]?.avatar : process.env.REACT_APP_STORAGE_URL + 'images/default_avatar.png')} alt="" />
					</div>
					<div>
					<div className={s.name}>
						{chat?.participants[0]?.name}
					</div>
					<div className={classNames(s.status, chat?.participants[0]?.online && s.online)}>
						{chat?.participants[0]?.online ? 'В сети' : 'Не в сети'}</div>
					</div>
				</div>
				<div className={s.messagesContent} id="scrollableDiv">
					<InfiniteScroll
						dataLength={messageHistory?.length}
						next={fetchMoreData}
						style={{ display: 'flex', flexDirection: 'column-reverse' }}
						inverse={true} 
						loader={<Loader size={18}/>}
						className={s.messages}
						hasMore={!isEnd}
						scrollThreshold={.3}
						scrollableTarget="scrollableDiv"
					>
						{messageHistory?.length > 0 && messageHistory.map((m, index) => 
							<Message 	
												key={'message-key-' + m.id}
												isMine={profile.id === m.sender_id} 
												message={m} 
												user_id={profile.id}
												chat_id={chat?.id}
												{...{index, onRead, months, messageHistory}}/>)}
					</InfiniteScroll>
				</div>
				{chat?.participants[0]?.role !== 'service' ? <SendBlock {...{text, setText, handleEnter, emojiShow, setEmojiShow, file, setFile}}/> : null}
			</>
			 : 
			 <div className={s.withoutChat}>
				<span>Выберите чат, чтобы начать общаться</span>	
			</div>}
		</div>
	)
}
