import React from "react";
import { AppointmentNote } from "../../../components/profile/appointment-note/appointment-note";
import { HomeworkCard } from "../../../components/profile/homework-card/homework-card";
import { AppointmentWrapper } from "../../../components/shared/appointment-wrapper/appointment-wrapper";

export const Appointment = () => {
  return (
		<AppointmentWrapper {...{AppointmentNote, HomeworkCard}}/>
	)
};
