import React from 'react'
import s from './appointment-header.module.scss'
import { IconChevronUp, IconRefresh } from '@tabler/icons-react'
import classNames from 'classnames'
import { Button } from '../button/button';

export const AppointmentHeader = ({mode, setMode, setIsBottomSheetShown, shown, refetch, isMobile, isTeacher, has_unreviewed_homework, appointmentIsFetching, has_new_homework}) => {
	return (
		<div className={classNames(s.appointmentHeader, shown ? s.shown : '')}>
			{isMobile ? (
				<div
					className={s.chevronFlex}
					onClick={() => setIsBottomSheetShown((p) => !p)}
				>
					<IconChevronUp className={s.iconChevron} />
					<span>Материалы и задания</span>
				</div>
			) : (
				<div className={s.title}>
					Занятие
					<Button label={<IconRefresh size={16} className={appointmentIsFetching ? s.fetching : ''}/>} onClick={refetch} size="small" theme="secondary" className={s.refreshBtn}/>	
				</div>
			)}
			<div className={classNames(s.tabs, mode === 0 ? s.active : "")}>
				<button
					className={classNames(s.tab, mode === 0 ? s.active : "")}
					onClick={() => setMode(0)}
				>
					Материалы занятия
				</button>
				<button
					className={classNames(s.tab, mode === 1 ? s.active : "")}
					onClick={() => setMode(1)}
				>
					Домашнее задание
					{isTeacher ? (
						has_unreviewed_homework ? (
							<span
								className={s.unread}
								data-tooltip-id="tooltip-has-new-homework-result"
							></span>
						) : (
							""
						)
					) : has_new_homework ? (
						<span
							className={s.unread}
							data-tooltip-id="tooltip-has-new-homework"
						></span>
					) : (
						""
					)}
				</button>
			</div>
		</div>
	)
}
