import React from 'react'
import s from './audio-player.module.scss'
import AudioPlayerComponent, { RHAP_UI } from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { IconPlayerPause, IconPlayerPlay, IconTrash } from '@tabler/icons-react';
import classNames from 'classnames';

export const AudioPlayer = ({src, file, remove, fullWidth}) => {
	return (
		<AudioPlayerComponent
			src={src ? src : URL.createObjectURL(file)}
			layout="horizontal-reverse"
			className={classNames(s.player, fullWidth ? s.fullWidth : '')}
			customIcons={{
				play: <IconPlayerPlay color='rgba(0, 96, 255, 1)' size={18}/>,
				pause: <IconPlayerPause color='rgba(0, 96, 255, 1)' size={18}/>,
			}}
			autoPlay={false}
			customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
			showJumpControls={false}
			customProgressBarSection={[ RHAP_UI.PROGRESS_BAR, RHAP_UI.CURRENT_TIME,
				remove ? <button className={s.removeBtn} onClick={() => remove()}>
					<IconTrash size={18} color={'#F12828'}/>
				</button> : null
			]}
		/>
	)
}
