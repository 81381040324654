import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import s from './chat.module.scss'
import { IconCheck, IconChecks } from '@tabler/icons-react';
import { formatFileSize } from 'helpers/formatFileSize';
import { getFileTypeIcon } from 'helpers/fileTypesIcons';
import { Images } from './images';
import { downloadFile } from '../../../helpers/downloadFile';
import { isImage } from '../../../helpers/isImage';

export const Message = ({ isMine, message, onRead, user_id, chat_id, months, index, messageHistory }) => {
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && message.is_read === false && !isMine) {
      onRead(message.id, chat_id, user_id);
    }
  }, [inView, messageHistory]);

  const date = new Date(message.timestamp * 1000)
	const timeString = date.getHours() + ':' + (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
	const messageDate = date.getDate() + ' ' + months[date.getMonth()]
	const nextMessageDate = index < messageHistory.length - 1 ? new Date(parseInt(messageHistory[index + 1].timestamp) * 1000) : null;
	const nextMessageDateString = nextMessageDate ? nextMessageDate.getDate() + ' ' + months[nextMessageDate.getMonth()] : null
	const dateElement = messageDate !== nextMessageDateString ? <div className={s.date}>{messageDate}</div> : null;
	
	return (
		<>
			<div className={classNames(s.message, isMine ? s.mine : null, (isImage(message?.attachments[0]) && !message.data) ? s.onlyImg : null)} ref={ref}>
					{message.attachments[0] && (isImage(message?.attachments[0]) ? <Images images={[process.env.REACT_APP_STORAGE_URL + message.attachments[0].file]}/> : null)}
					<div className={s.flex}>
						<span className={s.textWrapper}>
							{message.data && <span className={s.text}>{message.data}</span>}
							{message?.attachments[0] && 
								(isImage(message.attachments[0]) ? null :
								<div className={s.fileBlock}>
									<div className={s.fileIcon} onClick={() => downloadFile(process.env.REACT_APP_STORAGE_URL + message.attachments[0].file)}>
										{getFileTypeIcon(message.attachments[0].name.split('.').pop())}
									</div>
									<div className={s.fileInfo}>
										<div onClick={() => downloadFile(process.env.REACT_APP_STORAGE_URL + message.attachments[0].file)} className={s.fileName}>{message.attachments[0]?.name}</div>
										<p className={s.fileSize}>{formatFileSize(message.attachments[0].size)}</p>
									</div>
								</div>)}	
						</span>
						<span className={s.timestamp}>
							{timeString}
							{isMine && (message.is_read ? <IconChecks className={s.readIcon} size={14}/> : <IconCheck className={s.unreadIcon} size={14}/>) }
						</span>
					</div>
			</div>
			{dateElement}
		</>
	);
};