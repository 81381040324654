import React from 'react'
import s from './appointment-content-block.module.scss'
import { isAudio, isImage } from '../../../helpers/isImage';
import { downloadFile } from '../../../helpers/downloadFile';
import { IconDownload, IconFile } from '@tabler/icons-react';
import { AudioPlayer } from '../audio-player/audio-player';

export const AppointmentContentBlock = ({attachments, text}) => {
	return (
		<>
			<div className={s.text}>{text}</div>
			{attachments?.map(attachment => {
				if(isImage(attachment)){
					return <div className={s.img}>
						<img src={process.env.REACT_APP_STORAGE_URL + attachment.file} alt="" />
					</div>
				}else{
					if(isAudio(attachment)){
						return <div className={s.fileWrapper}>
							<AudioPlayer fullWidth src={process.env.REACT_APP_STORAGE_URL + attachment.file}/>
							<button className={s.downloadBtn} onClick={() => downloadFile(process.env.REACT_APP_STORAGE_URL + attachment.file)}>
								<div className={s.icon}>
									<IconDownload size={18}/>
								</div>
							</button>
						</div>
					}else{
						return (
							<div className={s.fileWrapper}>
								<p onClick={() => downloadFile(process.env.REACT_APP_STORAGE_URL + attachment.file)}>
									<div className={s.icon}>
										<IconFile size={18}/>
									</div>
									{attachment.name}
								</p>
							</div>)
						}
					}
			})}
		</>
	)
}
