import React, { useState, useEffect } from 'react';
import { stopwatch } from '../../../helpers/stopwatch';
import s from './time-display.module.scss'

export function TimeDisplay({ startsAt }) {
  const [currentTime, setCurrentTime] = useState('');

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(stopwatch(startsAt));
    }, 1000);

    return () => clearInterval(timer);
  }, [startsAt]);

  return <div className={s.flex}>
		{currentTime}
	</div>;
}