import React from 'react'
import s from './appointment-note.module.scss'
import { AppointmentContentBlock } from '../../shared/appointment-content-block/appointment-content-block'

export const AppointmentNote = ({note}) => {
	return (
		<div className={s.contentBlock}>
			<AppointmentContentBlock attachments={note?.attachments} text={note?.text}/>
		</div>
	)
}