import s from './home.module.scss'
import { NavLink } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import "swiper/css/free-mode";
import 'swiper/css/autoplay';
import classNames from 'classnames';
import Marquee from "react-simple-marquee";
import { useWindowSize } from '../../hooks/useWindowSize';

export const HomePage = () => {
	const { width, height } = useWindowSize()

  return (
		<>
			<section className={s.firstScreen}>
				<video 
					className={s.img} 
					loop 
					autoPlay 
					muted 
					controls={false}
					poster={process.env.REACT_APP_STORAGE_URL + "video/bg-preview.png"}>
					<source src={process.env.REACT_APP_STORAGE_URL + "video/bg.mp4"} type="video/mp4" />
					<source src={process.env.REACT_APP_STORAGE_URL + "video/bg.ogv"} type="video/ogv" />
					<source src={process.env.REACT_APP_STORAGE_URL + "video/bg.webm"} type="video/webm" />
				</video>
				<h1 className={s.title}>
				СТАНЬ ЧАСТЬЮ МИРА <br /><span className={s.accent}>МУЗЫКИ</span> ВМЕСТЕ С <br /><span className={s.accent}>СОЗВУЧНО</span>
				</h1>
				<div className={s.btns}>
					<NavLink to={'/sign-up'} className={s.button}>Начать заниматься</NavLink>
					<NavLink to={'/login'} className={s.buttonSignIn}>Войти в аккаунт</NavLink>
				</div>
			</section>
			<section className={s.whyOur}>
				<div className="container">
					<div className={s.header}>
						<p className={s.title}>
						Почему мы?
						</p>
						<p className={s.description}>
							Когда дело касается обучения, надежность, безопасность и удобство играют решающую роль. Мы понимаем, что выбор преподавателя - это важный шаг на пути к достижению ваших образовательных целей. Поэтому мы предлагаем уникальные преимущества, которые делают процесс обучения более удобным, безопасным и эффективным для всех наших учеников.
						</p>
					</div>
					<div className={s.grid}>
						<div className={s.gridItem}>
							<div className={s.gridItemContent}>
								<div className={s.gridItemTitle}>Проверенные преподаватели</div>
								<div className={s.gridItemDescription}>Мы тщательно проверяем образование и опыт каждого преподавателя, чтобы вы могли быть уверены в их квалификации.</div>
							</div>
							<div className={s.gridItemIcon}>
								<img src={process.env.REACT_APP_STORAGE_URL + "images/home-page/Exclude.svg"} alt="" />
								<img src={process.env.REACT_APP_STORAGE_URL + "images/home-page/filled/Exclude.svg"} alt="" />
							</div>
						</div>
						<div className={s.gridItem}>
							<div className={s.gridItemContent}>
								<div className={s.gridItemTitle}>Экономия времени</div>
								<div className={s.gridItemDescription}>Не теряйте время на дорогу к преподавателю и обратно. Уроки проходят онлайн, что позволяет вам сэкономить драгоценные часы.</div>
							</div>
							<div className={s.gridItemIcon}>
								<img src={process.env.REACT_APP_STORAGE_URL + "images/home-page/Group 2102.svg"} alt="" />
								<img src={process.env.REACT_APP_STORAGE_URL + "images/home-page/filled/Group 2102.svg"} alt="" />
							</div>
						</div>
						<div className={s.gridItem}>
							<div className={s.gridItemContent}>
								<div className={s.gridItemTitle}>Безопасная оплата</div>
								<div className={s.gridItemDescription}>Ваши деньги переводятся преподавателю только после успешного завершения занятия, обеспечивая вам защиту и уверенность.</div>
							</div>
							<div className={s.gridItemIcon}>
								<img src={process.env.REACT_APP_STORAGE_URL + "images/home-page/Vector 6.svg"} alt="" />
								<img src={process.env.REACT_APP_STORAGE_URL + "images/home-page/filled/Vector 6.svg"} alt="" />
							</div>
						</div>
						<div className={s.gridItem}>
							<div className={s.gridItemContent}>
								<div className={s.gridItemTitle}>Рыночные цены</div>
								<div className={s.gridItemDescription}>Мы предлагаем конкурентоспособные цены, основанные на опыте и квалификации каждого преподавателя, чтобы вы получили максимальную ценность за свои инвестиции в обучение.</div>
							</div>
							<div className={s.gridItemIcon}>
								<img src={process.env.REACT_APP_STORAGE_URL + "images/home-page/Group 2115.svg"} alt="" />
								<img src={process.env.REACT_APP_STORAGE_URL + "images/home-page/filled/Group 2115.svg"} alt="" />
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className={s.teachers}>
					<div className={s.header}>
						<div className="container">
							<p className={s.title}>
								Обучайся у профессионалов
							</p>
						</div>
					</div>
					<div className={s.flex}>
						<Swiper
							spaceBetween={10}
							slidesPerView={width/400}
							autoplay={{delay: 1000, pauseOnMouseEnter: true}}
							speed={2000}
							freeMode
							modules={[Autoplay]}
							loop
						>
							<SwiperSlide className={s.slide}>
								<div className={s.slideWrapper}>
									<div className={s.slideContent}>
										<div className={s.img}>
											<p>
												Музыка не главное в моей жизни. Главное — это быть человеком, найти себя и быть счастливым. Музыка — это половина меня, но это только составляющая моей жизни.
												<img src={process.env.REACT_APP_STORAGE_URL + "images/home-page/teachers/noise.png"} className={s.noise} alt="" />
											</p>
											<img src={process.env.REACT_APP_STORAGE_URL + "images/home-page/teachers/aaron-blanco-tejedor--GsPtETXbfo-unsplash.jpg"} alt="" />
										</div>
										<div className={s.name}>VLADISLAV ALEKSANDROV</div>
										<div className={s.instrument}>Барабаны</div>
									</div>
								</div>
							</SwiperSlide>
							<SwiperSlide className={s.slide}>
								<div className={s.slideWrapper}>
									<div className={s.slideContent}>
										<div className={s.img}>
											<p>
												Музыка не главное в моей жизни. Главное — это быть человеком, найти себя и быть счастливым. Музыка — это половина меня, но это только составляющая моей жизни.
												<img src={process.env.REACT_APP_STORAGE_URL + "images/home-page/teachers/noise.png"} className={s.noise} alt="" />
											</p>
											<img src={process.env.REACT_APP_STORAGE_URL + "images/home-page/teachers/andrew-spencer-08-c5-GxA7M-unsplash.jpg"} alt="" />
										</div>
										<div className={s.name}>MATVEY STEPANOV</div>
										<div className={s.instrument}>Духовые</div>
									</div>
								</div>
							</SwiperSlide>
							<SwiperSlide className={classNames(s.slide, s.toTop)}>
								<div className={s.slideWrapper}>
									<div className={s.slideContent}>
										<div className={s.img}>
											<p>
												Музыка не главное в моей жизни. Главное — это быть человеком, найти себя и быть счастливым. Музыка — это половина меня, но это только составляющая моей жизни.
												<img src={process.env.REACT_APP_STORAGE_URL + "images/home-page/teachers/noise.png"} className={s.noise} alt="" />
											</p>
											<img src={process.env.REACT_APP_STORAGE_URL + "images/home-page/teachers/marco-chilese-NJr0KzCYPVA-unsplash.jpg"} alt="" />
										</div>
										<div className={s.name}>EGOR OVCHINNIKOV</div>
										<div className={s.instrument}>Клавишные</div>
									</div>
								</div>
							</SwiperSlide>
							<SwiperSlide className={s.slide}>
								<div className={s.slideWrapper}>
									<div className={s.slideContent}>
										<div className={s.img}>
											<p>
												Музыка не главное в моей жизни. Главное — это быть человеком, найти себя и быть счастливым. Музыка — это половина меня, но это только составляющая моей жизни.
												<img src={process.env.REACT_APP_STORAGE_URL + "images/home-page/teachers/noise.png"} className={s.noise} alt="" />
											</p>
											<img src={process.env.REACT_APP_STORAGE_URL + "images/home-page/teachers/andrew-spencer-08-c5-GxA7M-unsplash.jpg"} alt="" />
										</div>
										<div className={s.name}>MATVEY STEPANOV</div>
										<div className={s.instrument}>Духовые</div>
									</div>
								</div>
							</SwiperSlide>
							<SwiperSlide className={s.slide}>
								<div className={classNames(s.slideWrapper, s.toTop)}>
									<div className={s.slideContent}>
										<div className={s.img}>
											<p>
												Музыка не главное в моей жизни. Главное — это быть человеком, найти себя и быть счастливым. Музыка — это половина меня, но это только составляющая моей жизни.
												<img src={process.env.REACT_APP_STORAGE_URL + "images/home-page/teachers/noise.png"} className={s.noise} alt="" />
											</p>
											<img src={process.env.REACT_APP_STORAGE_URL + "images/home-page/teachers/marco-chilese-NJr0KzCYPVA-unsplash.jpg"} alt="" />
										</div>
										<div className={s.name}>EGOR OVCHINNIKOV</div>
										<div className={s.instrument}>Клавишные</div>
									</div>
								</div>
							</SwiperSlide>
							<SwiperSlide className={s.slide}>
								<div className={s.slideWrapper}>
									<div className={s.slideContent}>
										<div className={s.img}>
											<p>
												Музыка не главное в моей жизни. Главное — это быть человеком, найти себя и быть счастливым. Музыка — это половина меня, но это только составляющая моей жизни.
												<img src={process.env.REACT_APP_STORAGE_URL + "images/home-page/teachers/noise.png"} className={s.noise} alt="" />
											</p>
											<img src={process.env.REACT_APP_STORAGE_URL + "images/home-page/teachers/andrew-spencer-08-c5-GxA7M-unsplash.jpg"} alt="" />
										</div>
										<div className={s.name}>MATVEY STEPANOV</div>
										<div className={s.instrument}>Духовые</div>
									</div>
								</div>
							</SwiperSlide>
						</Swiper>
						<div className={s.invite}>
							<p>
							Хочешь присоединиться к нам в качестве преподавателя? Отправьте свое резюме нам на почту, и возможно вы станете частью команды
							</p>
							<div className={s.inviteFooter}>
								<NavLink className={s.btn} to={'/sign-up'}>Присоединиться к команде</NavLink>
							</div>
						</div>
					</div>
			</section>
			<div className={s.marqueeWrapper}>
				<Marquee
					speed={2} // Speed of the marquee (Optional)
					style={{
						padding: '2.1875rem 0rem 2.25rem 0rem'
					}}
				>
					СОЗВУЧНО
					<NavLink className={s.btn} to={'/sign-up'}>Начать заниматься</NavLink>
					СОЗВУЧНО
					<NavLink className={s.btn} to={'/sign-up'}>Начать заниматься</NavLink>
					СОЗВУЧНО
					<NavLink className={s.btn} to={'/sign-up'}>Начать заниматься</NavLink>
					СОЗВУЧНО
					<NavLink className={s.btn} to={'/sign-up'}>Начать заниматься</NavLink>
				</Marquee>
			</div>
			<section className={s.howItWorks}>
				<div className="container">
					<div className={s.header}>
						<p className={s.title}>
						Как это работает
						</p>
						<div className={s.flex}>
							<p className={s.description}>
							Не нашли ответ на свой вопрос? Напишите нам в телеграм - ответим вам в течении дня.
							</p>
							<a href="mailto:contact@sozvuchno.ru" className={s.button}>Задать вопрос</a>
						</div>
					</div>
					<div className={s.grid}>
						<div className={s.gridItem}>
							<div className={s.gridItemContent}>
								<div className={s.gridItemNumber}>1.</div>
								<div className={s.gridItemTitle}>Выберите своего музыкального наставника</div>
								<div className={s.gridItemDescription}>Ознакомьтесь с нашими квалифицированными преподавателями и выберите того, кто лучше всего подходит для ваших потребностей и стиля.</div>
							</div>
							<div className={s.gridItemIcon} style={{right: 0, bottom: ' -1.03856rem'}}>
								<img src={process.env.REACT_APP_STORAGE_URL + "images/home-page/how-it-work/active/man.svg"} alt="" />
								<img src={process.env.REACT_APP_STORAGE_URL + "images/home-page/how-it-work/not-active/man.svg"} alt="" />
							</div>
						</div>
						<div className={s.gridItem}>
							<div className={s.gridItemContent}>
								<div className={s.gridItemNumber}>2.</div>
								<div className={s.gridItemTitle}>Запишитесь на удобное время</div>
								<div className={s.gridItemDescription}>Выберите удобное для вас время занятия из гибкого графика наших преподавателей.</div>
							</div>
							<div className={s.gridItemIcon} style={{left: '0.8125rem', bottom: '-1.625rem'}}>
								<img src={process.env.REACT_APP_STORAGE_URL + "images/home-page/how-it-work/active/time.svg"} alt="" />
								<img src={process.env.REACT_APP_STORAGE_URL + "images/home-page/how-it-work/not-active/time.svg"} alt="" />
							</div>
						</div>
						<div className={s.gridItem}>
							<div className={s.gridItemContent}>
								<div className={s.gridItemNumber}>3.</div>
								<div className={s.gridItemTitle}>Оплачивайте с уверенностью</div>
								<div className={s.gridItemDescription}>Оплатите занятие, зная, что ваш преподаватель получит вознаграждение только после успешно проведенного урока.</div>
							</div>
							<div className={s.gridItemIcon} style={{left: '-8.0625rem;', bottom: '-1.5rem'}}>
								<img src={process.env.REACT_APP_STORAGE_URL + "images/home-page/how-it-work/active/coins.svg"} alt="" />
								<img src={process.env.REACT_APP_STORAGE_URL + "images/home-page/how-it-work/not-active/coins.svg"} alt="" />
							</div>
						</div>
						<div className={s.gridItem}>
							<div className={s.gridItemContent}>
								<div className={s.gridItemNumber}>4.</div>
								<div className={s.gridItemTitle}>Присоединяйтесь к онлайн занятию</div>
								<div className={s.gridItemDescription}>Войдите в наше виртуальное классное помещение и начните урок в удобной для вас обстановке.</div>
							</div>
							<div className={s.gridItemIcon} style={{right: '-10.79019rem', bottom: '-9.71069rem'}}>
								<img src={process.env.REACT_APP_STORAGE_URL + "images/home-page/how-it-work/active/online.svg"} alt="" />
								<img src={process.env.REACT_APP_STORAGE_URL + "images/home-page/how-it-work/not-active/online.svg"} alt="" />
							</div>
						</div>
						<div className={s.gridItem}>
							<div className={s.gridItemContent}>
								<div className={s.gridItemNumber}>5.</div>
								<div className={s.gridItemTitle}>Оставьте обратную связь</div>
								<div className={s.gridItemDescription}>Поделитесь своими впечатлениями о занятии, помогая другим выбрать лучшего преподавателя.</div>
							</div>
							<div className={s.gridItemIcon} style={{right: '-0.5rem', top: '0.625rem'}}>
								<img src={process.env.REACT_APP_STORAGE_URL + "images/home-page/how-it-work/active/heart.svg"} alt="" />
								<img src={process.env.REACT_APP_STORAGE_URL + "images/home-page/how-it-work/not-active/heart.svg"} alt="" />
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className={s.contact}>
					<div className={s.infoWrapper}>
						<div className="container">
							<div className={s.flex}>
								<div className={s.title}>
								СВЯЖИСЬ С НАМИ
								</div>
								<div className={s.info}>
									<div className={s.desc}>
									Мы рады новым сотрудничествам и готовы услышать идеи по улучшению нашего продукта.
									</div>
									<div className={s.links}><a href="mailto:contact@sozvuchno.ru">contact@sozvuchno.ru</a></div>
								</div>
							</div>
						</div>
					</div>
					<div className={s.infoWrapper}>
						<div className="container">
							<div className={s.flex}>
								<div className={s.title}>
								СЛЕДИ ЗА НАМИ
								</div>
								<div className={s.info}>
									<div className={s.desc}>
									Следи за обновлениями и последними новостями в наших социальных сетях.
									</div>
									<div className={s.links}>
										<a href="https://t.me/sozvuchno_ed">Telegram</a>
										{/* <a href="">YouTube</a> */}
										<a href="https://vk.com/sozvuchno_music">Vk</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className={s.imageBlock}>
						<div className="container">
							<p>
								ТВОИ ЛЮБИМЫЕ АРТИСТЫ <br />
								ТОЖЕ КОГДА-ТО НАЧИНАЛИ
							</p>
							<img src={process.env.REACT_APP_STORAGE_URL + "images/home-page/footer-image.png"} alt="" />
						</div>
					</div>
			</section>
		</>
  );
}
