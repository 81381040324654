import s from './profile-info-card.module.scss'
import { NavLink } from 'react-router-dom'
import { IconEdit, IconShare2 } from '@tabler/icons-react';
import { getFullName } from '../../../helpers/getFullname';
import { Button } from '../../shared/button/button';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';

export const ProfileInfoCard = ({profile, analytics}) => {
	return (
		<div className={s.card}>
			<div className={s.profile}>
				<div className={s.avatar}>
					<img src={profile.avatar ? process.env.REACT_APP_STORAGE_URL + profile.avatar.image : process.env.REACT_APP_STORAGE_URL + 'images/default_avatar.png'} alt="" />
				</div>
				<div className={s.profileContent}>
					<div className={s.name}>{getFullName(profile)} <NavLink className={s.edit} to={'/settings'}><IconEdit size={18}/></NavLink></div>
					<div className={s.mail}>{profile?.email}</div>
				</div>
				<CopyToClipboard text={'https://sozvuchno.ru/techer/' + profile?.id} onCopy={() => toast.success('Ссылка скопирована')}>
					<Button className={s.shareBtn} label={<IconShare2 size={16}/>} theme="secondary" size="small"/>
				</CopyToClipboard>
			</div>
			<div className={s.analytics}>
				{analytics?.map((el, index) => (
					<div className={s.infoBlock} key={'analytics-' + index}>
						<div className={s.infoLabel}>
							{el.label}
						</div>
						<div className={s.infoValue}>
							{el.value}
						</div>
					</div>
				))}
			</div>		
		</div>
	)
}
