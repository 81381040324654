import React from "react";
import { AppointmentNote } from "../../../components/teacher-profile/appointment-note/appointment-note";
import { AddNoteCard } from "../../../components/teacher-profile/add-note-card/add-note-card";
import { AddHomeworkCard } from "../../../components/teacher-profile/add-homework-card/add-homework-card";
import { HomeworkCard } from "../../../components/teacher-profile/homework-card/homework-card";
import { AppointmentWrapper } from "../../../components/shared/appointment-wrapper/appointment-wrapper";

export const AppointmentTeacher = () => {
  return (
		<AppointmentWrapper isTeacher {...{AppointmentNote, AddNoteCard, AddHomeworkCard, HomeworkCard}}/>
  );
};
