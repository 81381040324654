import React, { useEffect, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Input } from 'shared/input/input'
import { Button } from 'shared/button/button';
import s from './register.module.scss'
import { Checkbox } from 'shared/checkbox/checkbox';
import * as VKID from '@vkid/sdk';
import classNames from 'classnames';
import { Controller, FormProvider } from 'react-hook-form';

const errors = {
	401: 'Пароль или почта введены неверно',
	422: 'Почта уже используется',
}

export const Register = ({handleSubmit, referral, errorTeacher, error, methods, formState, handleSubmitTeacher, methodsTeacher, formStateTeacher}) => {
	VKID.Config.set({
		app: 51833501,
		redirectUrl: referral ? 'https://sozvuchno.ru/auth/vk/redirect?referral=' + referral : 'https://sozvuchno.ru/auth/vk/redirect', 
	});

	const containerRef = useRef(null);
	let hasRendered = false;
	const [role, setRole] = useState('student')

	useEffect(() => {
		const oneTap = new VKID.OneTap();
		if (containerRef.current && !hasRendered) {
			hasRendered = true
			oneTap.render({ container: containerRef.current });
		}
	}, [role]);

	const policy = methods?.watch('policy')
	const name = methods?.watch('name')
	const email = methods?.watch('email')
	const password = methods?.watch('password')
	const formFull = policy && name && email && password

	const notValid = (!formState.isValid && formState.isDirty) || !formFull 
	
	return (
		<div className={s.wrapper}>
			<div className={s.card}>
				<NavLink to={'/'} className={s.logo}>
					<img src={process.env.REACT_APP_STORAGE_URL + "images/logo.svg"} alt="Созвучно - сервис по поиску преподавателей музыки"/>
				</NavLink>
				<h2 className={s.title}>
				Регистрация
					<p className={s.register}>
						Уже есть аккаунт?
						<NavLink to={'/login'}> Войти</NavLink>
					</p>
				</h2>
				<div className={s.rolesTitle}>
					Я планирую
				</div>
				<div className={s.rolesWrapper}>
					<span className={classNames(s.rolesOverlap, role === 'student' ? s.student : s.teacher)}></span>
					<button onClick={() => setRole('student')} className={classNames(s.roleBtn, role === 'student' ? s.active : '')}>Учиться</button>
					<button onClick={() => setRole('teacher')} className={classNames(s.roleBtn, role === 'teacher' ? s.active : '')}>Преподавать</button>
				</div>
				{role === 'student' ? <FormProvider {...methods}>
					{error && <span className={s.error}>{errors[error?.status]}</span>}
					<form onSubmit={handleSubmit} className={s.form}>
						<Input 
							placeholder='Иван' 
							name='name' 
							className={s.input}
							title='Ваше имя'
							validation={{
								required: {
									value: true,
									message: 'Заполните поле',
								},
								minLength: {
									value: 2,
									message: 'Минимум 2 символов',
								},
								maxLength: {
									value: 255,
									message: 'Максимум 255 символов',
								},
								validate: {
									matchPattern: (v) =>
									/^[А-ЯЁ][а-яё]*$/.test(v) ||
										"Имя заполнено не верно",
								},
						}}/>
						<Input 
							placeholder='example@gmail.com' 
							name='email' 
							className={s.input}
							title='Ваша эл. почта'
							validation={{
								required: {
									value: true,
									message: 'Заполните поле',
								},
								validate: {
									maxLength: (v) =>
										v.length <= 50 || "Почта должна содержать не более 50 символов.",
									matchPattern: (v) =>
										/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
										"Адрес электронной почты недействителен.",
								},
						}}/>
						<Input 
							type="password" 
							placeholder='Пароль' name="password" 
							className={s.input}
							title='Пароль'
							validation={{
								required: {
									value: true,
									message: 'Поле незаполнено',
								},
								minLength: {
									value: 8,
									message: 'Минимум 8 символов',
								},
								maxLength: {
									value: 50,
									message: 'Максимум 50 символов',
								},
						}}/>
						<div className={s.checkboxes}>
							<Checkbox label={<span className={s.policy}>Принять <NavLink to={'/policy'}>Условия и положения</NavLink></span>} name="policy" className={s.checkbox}/>
						</div>
						<Button label='Создать аккаунт' className={s.button} disabled={notValid}/>
					</form>
					<div className={s.or}>
						Или
					</div>
					<div className={s.socialBtns} ref={containerRef}>
					</div>
				</FormProvider> : <FormProvider {...methodsTeacher}>
				{errorTeacher && <span className={s.error}>{errors[errorTeacher?.status]}</span>}
					<form onSubmit={handleSubmitTeacher} className={s.form}>
						<Input 
							placeholder='Иван' 
							name='name' 
							className={s.input}
							title='Ваше имя'
							required
							validation={{
								required: {
									value: true,
									message: 'Заполните поле',
								},
								minLength: {
									value: 2,
									message: 'Минимум 2 символов',
								},
								maxLength: {
									value: 255,
									message: 'Максимум 255 символов',
								},
								validate: {
									matchPattern: (v) =>
									/^[А-ЯЁ][а-яё]*$/.test(v) ||
										"Имя заполнено не верно",
								},
						}}/>
						<Input 
							placeholder='example@gmail.com' 
							name='email' 
							className={s.input}
							title='Ваша эл. почта'
							validation={{
								required: {
									value: true,
									message: 'Заполните поле',
								},
								validate: {
									maxLength: (v) =>
										v.length <= 50 || "Почта должна содержать не более 50 символов.",
									matchPattern: (v) =>
										/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
										"Адрес электронной почты недействителен.",
								},
						}}/>
						<Input 
							placeholder='+7(999) 999 99-99' 
							name='phone' 
							className={s.input}
							title='Ваш телефон'
							mask="+9(999) 999-99-99"
							validation={{
								required: 'Телефон обязателен',
								pattern: {
									value: /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/,
									message: 'Неверный формат телефона',
								},
						}}/>
						<Controller
							name="message"
							control={methodsTeacher.control}
							defaultValue=""
							rules={{
								maxLength: {
									value: 500,
									message: 'Сообщение должно содержать не более 500 символов',
								},
							}}
							render={({ field }) => (
								<textarea
									{...field}
									className={s.textarea}
									placeholder="Дополнительная информация, необходимая для связи с вами"
								/>
							)}
						/>
						<div className={s.checkboxes}>
							<Checkbox label={<span className={s.policy}>Принять <NavLink to={'/policy'}>Условия и положения</NavLink></span>} name="policy" className={s.checkbox}/>
						</div>
						<Button label='Отправить запрос' className={s.button} disabled={!formStateTeacher.isValid || !formStateTeacher.isDirty || !methodsTeacher.watch('policy')}/>
					</form>
				</FormProvider>}
			</div>
		</div>
	)
}
