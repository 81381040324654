import React, { useContext, useState } from "react";
import s from "./homework-card.module.scss";
import { IconPencil, IconTrash } from "@tabler/icons-react";
import { ModalsContext } from "../../layouts/dashboard-layout/dashboard";
import { EditNote } from "../edit-note-card/edit-note-card";
import { SubmissionCard } from "../homework-submission-card/homework-submission-card";
import { AppointmentContentBlock } from "../../shared/appointment-content-block/appointment-content-block";

export const HomeworkCard = ({ homework, appointmentId, appointment }) => {
  const [isEditable, setIsEditable] = useState(false);
  const { setModal } = useContext(ModalsContext);

  if (isEditable) {
    return (
      <EditNote
        {...{ homework, appointmentId }}
        onSuccess={() => setIsEditable(false)}
      />
    );
  }

  return (
    <>
      <div className={s.contentBlock}>
				<AppointmentContentBlock attachments={homework?.attachments} text={homework?.task}/>
        <div className={s.btns}>
          <button className={s.editBtn} onClick={() => setIsEditable(true)}>
            <IconPencil />
          </button>
          <button
            className={s.removeBtn}
            onClick={() =>
              setModal("remove-homework", {appointmentId, homeworkId: homework.id})
            }
          >
            <IconTrash />
          </button>
        </div>
      </div>
      {homework?.submissions?.map((submission) => (
        <SubmissionCard {...{ submission, appointment, homework }} />
      ))}
    </>
  );
};
