import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { Input } from 'shared/input/input'
import { Button } from 'shared/button/button';
import s from './forget-password.module.scss'
import { FormProvider, useForm } from 'react-hook-form';
import { auth } from 'api/api';
 
const errors = {
	401: 'Аккаунта с такой почтой не существует',
}

export const ForgetPassword = () => {
	const methods = useForm({mode: 'onTouched'})
	const [error, setError] = useState()
	const navigate = useNavigate()

  const handleSubmit = methods.handleSubmit(data => {
		const loginPayload = {
			email: data.email,
		}
	
		auth.forgotPassword(loginPayload).then((res) => {
			if(res.status == 200){
				navigate('/waiting-restore')
			}
		}).catch((response) => {
			setError(response.response)
		})
  })

	return (
		<div className={s.wrapper}>
			<div className={s.card}>
				<NavLink to={'/'} className={s.logo}>
					<img src={process.env.REACT_APP_STORAGE_URL + "images/logo.svg"} alt="Созвучно - сервис по поиску преподавателей музыки"/>
				</NavLink>
				<h2 className={s.title}>
					Восстановление пароля
				</h2>
				{error && <span className={s.error}>{errors[error?.status]}</span>}				<FormProvider {...methods}>

					<form onSubmit={handleSubmit} className={s.form}>
						<Input 
							placeholder='Email' 
							name='email' 
							className={s.input}
							validation={{
								required: {
									value: true,
									message: 'Заполните поле',
								},
								validate: {
									maxLength: (v) =>
										v.length <= 50 || "Почта должна содержать не более 50 символов.",
									matchPattern: (v) =>
										/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
										"Адрес электронной почты недействителен.",
								},
							}}/>
						<Button label='Восстановить' className={s.button}/>
					</form>
				</FormProvider>
			</div>
		</div>
	)
}
